import { useHistory } from 'react-router-dom';
import { useUserProfile } from 'profiles/queries/profileHooks';
export function useRedirectIfLoggedIn() {
  const history = useHistory();
  const redirectToURL = sessionStorage.getItem('redirectToURL');

  const { data, isLoading, status, refetch } = useUserProfile();

  if (status === 'success') {
    const userPrimaryRole = data.message.primary_role ?? null;
    try {
      switch (userPrimaryRole) {
        case 'athlete':
          history.push(redirectToURL || `/${userPrimaryRole}/profiles`);
          break;
        case 'coach':
          history.push(redirectToURL || `/${userPrimaryRole}/profiles`);
          break;
        case 'institution':
          history.push(redirectToURL || `/${userPrimaryRole}/profiles`);
          break;
        default:
          break;
      }
    } catch (error) {
      console.debug(error.message);
    }
  }
  return { refetch, isLoading };
}
