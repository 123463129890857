import OtpInput from 'react-otp-input';

export default function Otp({ code, setCode, prefilledOtp }) {
  const handleChange = (code) => setCode(code);

  return (
    <div className="Otp">
      <OtpInput
        isDisabled={prefilledOtp}
        value={code}
        onChange={handleChange}
        numInputs={4}
        separator={<span style={{ width: '20px' }} />}
        isInputNum={true}
        shouldAutoFocus={true}
        containerStyle={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        inputStyle={{
          border: '1px solid lightgray',
          borderRadius: '8px',
          width: '4rem',
          height: '4rem',
          fontSize: '50px',
          color: 'lightgray',
          fontWeight: '400',
          caretColor: 'blue',
          boxShadow: '0 1px 2px rgba(16, 24, 40, 0.05)',
        }}
        focusStyle={{
          border: '1px solid lightgray',
        }}
        activeInput={0}
      />
    </div>
  );
}
