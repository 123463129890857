import React from 'react';
import PropTypes from 'prop-types';
import './styles/horizontalstepper.css';
// import { Link } from 'react-router-dom';

const HorizontalStepper = ({ steps }) => {
  return (
    <div className="flex justify-start pb-6">
      {steps.map((step, index) => (
        <div
          key={index}
          className={
            'flex flex-row items-center ' + (step.active || step.done ? 'active' : 'inactive')
          }
        >
          {/* <Link className={'flex items-center'} to={step.href}> */}
          <span
            style={{ flexShrink: 0 }}
            className={`rounded-full w-5 h-5 border-2 text-xs flex justify-center items-center ${
              step.done && 'checked bg-gray-900  border-gray-900'
            } ${step.active && 'text-white bg-gray-900  border-gray-900'}`}
          >
            {!step.done && index + 1}
          </span>
          &nbsp;{' '}
          <span className="subtext text-xs" title={step.description}>
            {step.title}
          </span>
          {index !== steps.length - 1 && <div>&nbsp;-&nbsp;</div>}
          {/* </Link> */}
        </div>
      ))}
    </div>
  );
};

HorizontalStepper.propTypes = {
  steps: PropTypes.array.isRequired,
};

export default HorizontalStepper;
