export const EditEmailForm = {
  display: 'form',
  components: [
    {
      label: 'Email',
      placeholder: 'sample@email.com',
      tableView: true,
      case: 'lowercase',
      validate: {
        required: true,
        customMessage: 'Kindly Enter a Valid Email ID',
      },
      key: 'email',
      type: 'email',
      input: true,
      $$hashKey: 'object:19',
      autofocus: false,
      inputType: 'email',
      prefix: '',
      suffix: '',
      defaultValue: '',
      protected: false,
      unique: false,
      persistent: true,
      hidden: false,
      clearOnHide: true,
    },
  ],
  page: 0,
};

export const EditPhoneForm = {
  display: 'form',
  components: [
    {
      label: 'Phone Number',
      inputMask: '9999999999',
      tableView: true,
      validate: {
        required: true,
        customMessage: 'Invalid Phone Number',
      },
      key: 'phone',
      type: 'phoneNumber',
      input: true,
      $$hashKey: 'object:18',
      autofocus: false,
      inputType: 'tel',
      placeholder: '',
      prefix: '',
      suffix: '',
      multiple: false,
      protected: false,
      unique: false,
      persistent: true,
      hidden: false,
      defaultValue: '',
      clearOnHide: true,
    },
  ],
  page: 0,
};

// {
//   display: 'form',
//   components: [
//     {
//       label: 'Phone Number',
//       inputMask: '9999999999',
//       tableView: true,
//       validate: {
//         required: true,
//       },
//       key: 'phone',
//       type: 'phoneNumber',
//       input: true,
//     },
//     {
//       type: 'button',
//       label: 'Submit and Change Phone Number',
//       key: 'submit',
//       disableOnInvalid: true,
//       block: true,
//       input: true,
//       tableView: false,
//     },
//   ],
// };
