export default {
  "display": "form",
  "components": [
    {
      "label": "Sport",
      "widget": "choicesjs",
      "tableView": true,
      "data": {
        "values": [
          {
            "label": "Archery",
            "value": "archery"
          },
          {
            "label": "Athletics",
            "value": "athletics"
          },
          {
            "label": "Badminton",
            "value": "badminton"
          },
          {
            "label": "Basketball",
            "value": "basketball"
          },
          {
            "label": "Boxing",
            "value": "boxing"
          },
          {
            "label": "Carrom",
            "value": "carrom"
          },
          {
            "label": "Chess",
            "value": "chess"
          },
          {
            "label": "Fencing",
            "value": "fencing"
          },
          {
            "label": "Football",
            "value": "football"
          },
          {
            "label": "Gymnastics",
            "value": "gymnastics"
          },
          {
            "label": "Handball",
            "value": "handball"
          },
          {
            "label": "Hockey",
            "value": "hockey"
          },
          {
            "label": "Judo",
            "value": "judo"
          },
          {
            "label": "Kabaddi",
            "value": "kabaddi"
          },
          {
            "label": "Karate",
            "value": "karate"
          },
          {
            "label": "KhoKho",
            "value": "khokho"
          },
          {
            "label": "Kurash",
            "value": "kurash"
          },
          {
            "label": "Shooting",
            "value": "shooting"
          },
          {
            "label": "Skating",
            "value": "skating"
          },
          {
            "label": "SpeedCubing",
            "value": "speedcubing"
          },
          {
            "label": "Squash",
            "value": "squash"
          },
          {
            "label": "Swimming",
            "value": "swimming"
          },
          {
            "label": "Table Tennis",
            "value": "tabletennis"
          },
          {
            "label": "Taekwondo",
            "value": "taekwondo"
          },
          {
            "label": "Tennis",
            "value": "tennis"
          },
          {
            "label": "Throw ball",
            "value": "throwball"
          },
          {
            "label": "Volleyball",
            "value": "volleyball"
          },
          {
            "label": "Water Polo",
            "value": "waterpolo"
          },
          {
            "label": "Wrestling",
            "value": "wrestling"
          },
          {
            "label": "Yogasana",
            "value": "yogasana"
          },
          {
            "label": "Youth MMA",
            "value": "youthmma"
          }
        ]
      },
      "validateWhenHidden": false,
      "key": "sport",
      "type": "select",
      "input": true
    },
    
    {
      "label": "Columns",
      "columns": [
        {
          "components": [
            {
              "label": "DOB",
              "displayInTimezone": "utc",
              "format": "yyyy-MM-dd",
              "tableView": false,
              "validate": {
                "required": true,
                "customMessage": "Please Enter Your Date of Birth"
              },
              "datePicker": {
                "disableWeekends": false,
                "disableWeekdays": false,
                "maxDate": "2019-12-31",
                "minDate": "2004-01-1"
              },
              "enableTime": false,
              "enableMinDateInput": false,
              "enableMaxDateInput": false,
              "key": "DOB",
              "customClass": "date-pick",
              "type": "datetime",
              "input": true,
              "errorLabel": "DOB",
              "widget": {
                "type": "calendar",
                "displayInTimezone": "utc",
                "useLocaleSettings": false,
                "allowInput": false,
                "mode": "single",
                "enableTime": false,
                "noCalendar": false,
                "format": "yyyy-MM-dd",
                "hourIncrement": 1,
                "minuteIncrement": 1,
                "time_24hr": false,
                "minDate": null,
                "disableWeekends": false,
                "disableWeekdays": false,
                "maxDate": null
              }
            }
          ],
          "width": 6,
          "offset": 0,
          "push": 0,
          "pull": 0,
          "size": "md",
          "currentWidth": 6
        },
        {
          "components": [
            {
              "label": "Gender",
              "widget": "choicesjs",
              "tableView": true,
              "validate": {
                "required": true
              },
              "errorLabel": "Gender",
              "data": {
                "values": [
                  {
                    "label": "Male",
                    "value": "male"
                  },
                  {
                    "label": "Female",
                    "value": "female"
                  }
                ]
              },
              "customClass": "gender",
              "key": "gender",
              "type": "select",
              "input": true
            }
          ],
          "width": 6,
          "offset": 0,
          "push": 0,
          "pull": 0,
          "size": "md",
          "currentWidth": 6
        }
      ],
      "key": "columns",
      "type": "columns",
      "input": false,
      "tableView": false
    },
    {
      "label": "Country",
      "widget": "choicesjs",
      "tableView": true,
      "validate": {
        "required": true
      },
      "errorLabel": "Required.",
      "dataSrc": "value",
      "data": {
        "values": [
          {
            "label": "India",
            "value": "India"
          }
        ]
      },
      "key": "country",
      "type": "select",
      "defaultValue": "India",
      "input": true,
      "disabled": true,
      "selectValues": "message",
      "lazyLoad": false,
      "disableLimit": false
    },
    {
      "label": "Columns",
      "columns": [
        {
          "components": [
            {
              "label": "State",
              "widget": "choicesjs",
              "tableView": true,
              "data": {
    "values": [
      {
        "label": "Andhra Pradesh",
        "value": "Andhra Pradesh"
      },
      {
        "label": "Arunachal Pradesh",
        "value": "Arunachal Pradesh"
      },
      {
        "label": "Assam",
        "value": "Assam"
      },
      {
        "label": "Bihar",
        "value": "Bihar"
      },
      {
        "label": "Chhattisgarh",
        "value": "Chhattisgarh"
      },
      {
        "label": "Goa",
        "value": "Goa"
      },
      {
        "label": "Gujarat",
        "value": "Gujarat"
      },
      {
        "label": "Haryana",
        "value": "Haryana"
      },
      {
        "label": "Himachal Pradesh",
        "value": "Himachal Pradesh"
      },
      {
        "label": "Jharkhand",
        "value": "Jharkhand"
      },
      {
        "label": "Karnataka",
        "value": "Karnataka"
      },
      {
        "label": "Kerala",
        "value": "Kerala"
      },
      {
        "label": "Madhya Pradesh",
        "value": "Madhya Pradesh"
      },
      {
        "label": "Maharashtra",
        "value": "Maharashtra"
      },
      {
        "label": "Manipur",
        "value": "Manipur"
      },
      {
        "label": "Meghalaya",
        "value": "Meghalaya"
      },
      {
        "label": "Mizoram",
        "value": "Mizoram"
      },
      {
        "label": "Nagaland",
        "value": "Nagaland"
      },
      {
        "label": "Odisha",
        "value": "Odisha"
      },
      {
        "label": "Punjab",
        "value": "Punjab"
      },
      {
        "label": "Rajasthan",
        "value": "Rajasthan"
      },
      {
        "label": "Sikkim",
        "value": "Sikkim"
      },
      {
        "label": "Tamil Nadu",
        "value": "Tamil Nadu"
      },
      {
        "label": "Telangana",
        "value": "Telangana"
      },
      {
        "label": "Tripura",
        "value": "Tripura"
      },
      {
        "label": "Uttar Pradesh",
        "value": "Uttar Pradesh"
      },
      {
        "label": "Uttarakhand",
        "value": "Uttarakhand"
      },
      {
        "label": "West Bengal",
        "value": "West Bengal"
      }
    ]
  },
              "validateWhenHidden": false,
              "key": "states",
              "type": "select",
              "input": true
            }
          ],
          "width": 6,
          "offset": 0,
          "push": 0,
          "pull": 0,
          "size": "md",
          "currentWidth": 6
        },
        {
          "components": [
            {
              "label": "City",
              "tableView": true,
              "key": "city",
              "dataSrc": "json",
              "validate": {
                "required": true
              },
              "errorLabel": "City",
              "data": {
                "json": [
                  "Almora",
                  "Almora Cantonment",
                  "Badrinathpuri",
                  "Bageshwar",
                  "Bahadarabad",
                  "Bajpur(Bazpur)",
                  "Banbasa",
                  "Bandiya(Bandia)",
                  "Bangherimahabatpur(Must)",
                  "Barkot",
                  "Bhagwanpur",
                  "Bharat Heavy Electricals Limited Ranipur",
                  "Bhimtal",
                  "Bhowali",
                  "Central Hope Town",
                  "Chakrata",
                  "Chamba",
                  "Chamoli Gopeshwar",
                  "Champawat",
                  "Clement Town",
                  "Dehradun",
                  "Dehradun Cantonment",
                  "Devaprayag",
                  "Dhaluwala",
                  "Dhandera",
                  "Dharchula",
                  "Didihat",
                  "Dineshpur",
                  "Dogadda",
                  "Doiwala",
                  "Dwarahat",
                  "Fatehpur Range, Dhamua Dunga Area",
                  "Gadarpur",
                  "Gangotri",
                  "Gochar(Gauchar)",
                  "Gumaniwala",
                  "Haldwani-cum-Kathgodam",
                  "Haldwani Talli",
                  "Hardwar(Haridwar)",
                  "Haripur Kalan",
                  "Herbertpur",
                  "Jagjeetpur",
                  "Jaspur",
                  "Jhabrera",
                  "Jiwangarh",
                  "Jonk",
                  "Joshimath(Jyotirmath)",
                  "Kaladhungi",
                  "Kanchal Gosain",
                  "Karnaprayag",
                  "Kashipur",
                  "Kashirampur",
                  "Kedarnath",
                  "Kela Khera",
                  "Khanjarpur",
                  "Kharak mafi",
                  "Khatima",
                  "Khatyari",
                  "Kichha",
                  "Kirtinagar",
                  "Kotdwara(Kotdwar)",
                  "Laksar",
                  "Lalkuan",
                  "Landaur(Landour)",
                  "Landhaura",
                  "Lansdowne",
                  "Lohaghat",
                  "Maholiya",
                  "Mahua Dabra Haripura",
                  "Mahua Kheraganj",
                  "Manglaur",
                  "Maohanpur Mohammadpur",
                  "Mehu Wala Mafi",
                  "Mukhani(Roopnagar, Basant Vihar Colony and Judges Farm)",
                  "Muni Ki Reti",
                  "Mussoorie",
                  "Nagala Imarti",
                  "Nagla",
                  "Nainital",
                  "Nainital Cantonment",
                  "Nandprayag(Nandaprayag)",
                  "Narendranagar",
                  "Natthan Pur",
                  "Natthuwa Wala",
                  "Padali Gujar",
                  "Padampur Sukhran",
                  "Pauri",
                  "Piran Kaliyar",
                  "Pithoragarh",
                  "Pratitnagar",
                  "Raipur",
                  "Ramnagar",
                  "Ranikhet",
                  "Rawali Mahdood",
                  "Rishikesh",
                  "Roorkee",
                  "Roorkee Cantonment",
                  "Rudraprayag",
                  "Rudrapur",
                  "Saidpura",
                  "Salempur Rajputan",
                  "Shafipur",
                  "Shahpur",
                  "Shaktigarh",
                  "Sitarganj",
                  "Srinagar",
                  "Sultanpur",
                  "Sunhaira",
                  "Tanakpur",
                  "Tehri",
                  "Umru Khurd",
                  "Uttarkashi",
                  "Vikasnagar",
                  "Virbhadra"
                ]
              },
              "type": "select",
              "selectValues": "message",
              "input": true
            }
          ],
          "width": 6,
          "offset": 0,
          "push": 0,
          "pull": 0,
          "size": "md",
          "currentWidth": 6
        }
      ],
      "key": "columns1",
      "type": "columns",
      "input": false,
      "tableView": false
    },
    {
      "type": "button",
      "label": "Submit",
      "key": "submit",
      "block": true,
      "customClass": "mt-8",
      "saveOnEnter": true,
      "disableOnInvalid": true,
      "input": true,
      "tableView": false
    }
  ]
}
