// import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { ErrorMessage, Field, Form, Formik } from 'formik';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import { checkValidPlatformInvite } from 'authentication/services/authentication';
import Loader from 'components/Loader';
import { useHistory } from 'react-router-dom';

export default function SignUpFormik({
  formFields,
  isAthleteOnly,
  setFormValues,
  handleSubmitAndVerify,
  tournamentId,
}) {
  const [error, setError] = useState(null);
  const history = useHistory();
  const valid = useQuery(
    ['checkplatformValid', sessionStorage?.institution, tournamentId, sessionStorage?.otp],
    () =>
      checkValidPlatformInvite({
        institutionId: sessionStorage?.institution,
        tournamentId,
        otp: sessionStorage?.otp,
      }),
    {
      retry: false,
      enabled: !!sessionStorage?.institution && !!sessionStorage?.otp,
    }
  );

  const validationSchema = Yup.object({
    phone: Yup.string()
      .required('Kindly Enter Your Phone Number')
      .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),
    // email: Yup.string()
    //   .email('Kindly Enter a Valid Email ID')
    //   .required('Kindly Enter a Valid Email ID'),
    role: Yup.string().required('I am is required'),
    athlete_full_name: Yup.string().when('role', {
      is: 'athlete',
      then: Yup.string()
        .min(2, 'Kindly Enter Your Full Name')
        .required('Kindly Enter Your Full Name')
        .test('is valid word', 'Kindly Enter Your Full Name', function (value) {
          if (value) {
            const temp = value.trim();
            return temp.length >= 2 && temp.split(' ').length >= 2;
          }
          return false;
        }),
    }),
    iAgree: Yup.bool().oneOf([true], 'Kindly Accept Terms & Conditions To Proceed'),
  });

  if (valid.isFetching) return <Loader />;
  if (valid.isFetched && valid?.data?.message === false) {
    return (
      <>
        <div className="text-red-900 bg-red-100 border-red-200 px-4 py-2">
          The Platform Invite Link You Have Used Does Not Exist. Please Click The Button Below To Sign Up
        </div>
        <button
          className="btn btn-primary btn-md btn-block mt-8 bg-gray-750 hover:bg-blue-800"
          onClick={() => {
            sessionStorage.clear();
            history.push('/signup');
          }}
        >
          Sign Up
        </button>
      </>
    );
  }

  return (
    <>
      {error && (
        <div className="text-red-900 bg-red-100 border-red-200 px-4 py-2">
          Please fix the following errors before submitting.
          <br />
          <span className="underline">{error?.error || error.message || error}</span>
        </div>
      )}
      <Formik
        initialValues={{
          athlete_full_name: '',
          coach_full_name: '',
          phone: '',
          email: '',
          institution_full_name: '',
          iAgree: false,
          ...formFields,
          role: isAthleteOnly ? 'athlete' : formFields.role || '',
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={async (values) => {
          // Create email from phone number
          const emailFromPhone = `${values.phone}@sfaplay.com`;

          // Update values with the new email format
          const updatedValues = {
            ...values,
            email: emailFromPhone,
          };

          handleSubmitAndVerify({ data: updatedValues }, setError);
        }}
      >
        {({ isSubmitting, values, setValues, isValid }) => {
          return (
            <Form id="signUp-form">
              {!isAthleteOnly ? (
                <div className="form-group has-feedback required" onClick={(e) => {
                  if (e.target.value === values.role && e.target.checked)
                    setValues({ ...values, role: '' });
                }}>
                  {/* <label className="col-form-label field-required">I am Athlete,</label> */}
                  <div role="group" aria-labelledby="my-radio-group" className="flex flex-wrap ml-2 hidden">
                    <div className="mx-2 my-1 md:my-0">
                      <Field
                        type="radio"
                        name="role"
                        value="athlete"
                        className="mr-2 form-check-input"
                        onChange={(e) => {
                          setFormValues({ ...formFields, role: e.target.value });
                          setValues({ ...values, role: e.target.value });
                        }}
                      />
                      <label className="text-sm">Athlete</label>
                    </div>
                  </div>
                  <ErrorMessage name="role" component="div" className="text-red-600 text-sm mt-2" />
                </div>
              ) : null}

              {values.role && (
                <>
                  <label className="font-semibold text-sm text-gray-650 pb-2 md:pb-1 block field-required">
                    {roleText(values.role)[1]}
                  </label>
                  <div className="flex w-full bg-white border-2 rounded-lg">
                    <Field
                      type="text"
                      name={roleText(values.role)[0]}
                      placeholder={roleText(values.role)[1]}
                      className="bg-red w-full p-2 border-0 rounded"
                    />
                  </div>
                  <ErrorMessage name={roleText(values.role)[0]} component="div" className="text-red-600 text-sm mt-2" />
                </>
              )}

              <label className="font-semibold text-sm text-gray-650 mt-6 pb-2 md:pb-1 block field-required">
                Phone Number
              </label>
              <div className="flex w-full bg-white border-2 rounded-lg">
                <Field
                  type="text"
                  name="phone"
                  placeholder="Enter phone number"
                  className="w-full p-2 border-0 rounded"
                />
              </div>
              <ErrorMessage name="phone" component="div" className="text-red-600 text-sm mt-2" />

              {/* <label className="font-semibold text-sm text-gray-650 mt-6 pb-2 md:pb-1 block field-required">
                Email (Auto-generated)
              </label>
              <div className="flex w-full bg-white border-2 rounded-lg">
                <div className="flex justify-center items-center pl-3 w-15">
                  <FontAwesomeIcon icon={faEnvelope} className="text-gray-450" />
                </div>
                <Field
                  type="text"
                  name="email"
                  placeholder="someone@domain.com"
                  disabled
                  className="w-full p-2 border-0 rounded"
                  value={`${values.phone}@sfaplay.com`}
                />
              </div> */}

              <label className="form-check-label mt-6">
                <Field className="form-check-input" type="checkbox" name="iAgree" />
                <span className="field-required">
                  I have read & accept the{' '}
                  <a target="_blank" href="/static/SFAPLAY.COM-Terms-&-Conditions.pdf">
                    Terms & Conditions
                  </a>
                </span>
              </label>
              <ErrorMessage name="iAgree" component="div" className="text-red-600 text-sm mt-2" />

              <button
                className="btn btn-primary btn-md btn-block mt-8 bg-gray-750 hover:bg-blue-800"
                type="submit"
                disabled={!values.phone?.length || !isValid || isSubmitting}
              >
                Submit
                {isSubmitting && <i className="fa fa-refresh fa-spin button-icon-right"></i>}
              </button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

function roleText(role) {
  if (role === 'athlete') {
    return ['athlete_full_name', 'Athlete Name'];
  } else if (role === 'coach') {
    return ['coach_full_name', 'Technical Official / Support Staff Name'];
  } else {
    return ['institution_full_name', 'Institution Name'];
  }
}
