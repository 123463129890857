export default {
  display: 'form',
  components: [
    {
      label: 'Phone Number (WhatsApp Number Preferred)',
      inputMask: '9999999999',
      tableView: true,
      key: 'phone',
      type: 'phoneNumber',
      input: true,
      validate: {
        required: true,
      },
      errorLabel: 'Phone number',
    },
    {
      customClass: 'FixWhatsappIcon',
      label:
        "<span class='flex items-start md:items-center gap-2'><img width='25' src='https://upload.wikimedia.org/wikipedia/commons/archive/6/6b/20210929162525%21WhatsApp.svg'  alt='Whatsapp Logo'/></span>",
      labelWidth: 100,
      tableView: false,
      defaultValue: false,
      key: 'ReceiveWhatsapp',
      type: 'checkbox',
      input: true,
      disabled: true,
      validate: {
        required: true,
        customMessage:
          'We need your permission to send you WhatsApp messages to keep you updated with latest tournament communication',
      },
    },
    {
      label: 'School Type',
      optionsLabelPosition: 'right',
      customClass: 'mt-5',
      inline: true,
      validate: {
        required: true,
      },
      errorLabel: 'School type',
      tableView: false,
      values: [
        {
          label: 'Government',
          value: 'Government',
          shortcut: '',
        },
        {
          label: 'Private',
          value: 'Private',
          shortcut: '',
        },
      ],
      key: 'schoolType',
      type: 'radio',
      input: true,
    },
    {
      label: 'Country',
      widget: 'choicesjs',
      tableView: true,
      validate: {
        required: true,
      },
      errorLabel: 'Required.',
      dataSrc: 'value',
      // dataSrc: 'url',
      data: {
        values: [
          {
            label: 'India',
            value: 'India',
          },
        ],
        // url: 'sfa_online.v1.registrations.signup.get_countries',
        // headers: [
        //   {
        //     key: '',
        //     value: '',
        //   },
        // ],
      },
      key: 'country',
      type: 'select',
      defaultValue: 'India',
      input: true,
      disabled: true,
      selectValues: 'message',
      lazyLoad: false,
      disableLimit: false,
    },
    {
      label: 'Columns',
      columns: [
        {
          components: [
            {
              label: 'State',
              tableView: true,
              key: 'state',
              dataSrc: 'value',
              validate: {
                required: true,
              },
              errorLabel: 'State',
              data: {
                values: [
                  {
                    label: 'Uttarakhand',
                    value: 'Uttarakhand',
                  },
                ],
                // url: 'sfa_online.v1.registrations.signup.get_states',
                // headers: [
                //   {
                //     key: '',
                //     value: '',
                //   },
                // ],
              },
              defaultValue: 'Uttarakhand',
              type: 'select',
              disabled: true,
              selectValues: 'message',
              input: true,
            },
          ],
          width: 6,
          offset: 0,
          push: 0,
          pull: 0,
          size: 'md',
          currentWidth: 6,
        },
        {
          components: [
            {
              label: 'City',
              tableView: true,
              key: 'city',
              dataSrc: 'json',
              validate: {
                required: true,
              },
              errorLabel: 'City',
              data: {
                json: [
                  'Almora',
                  'Almora Cantonment',
                  'Badrinathpuri',
                  'Bageshwar',
                  'Bahadarabad',
                  'Bajpur(Bazpur)',
                  'Banbasa',
                  'Bandiya(Bandia)',
                  'Bangherimahabatpur(Must)',
                  'Barkot',
                  'Bhagwanpur',
                  'Bharat Heavy Electricals Limited Ranipur',
                  'Bhimtal',
                  'Bhowali',
                  'Central Hope Town',
                  'Chakrata',
                  'Chamba',
                  'Chamoli Gopeshwar',
                  'Champawat',
                  'Clement Town',
                  'Dehradun',
                  'Dehradun Cantonment',
                  'Devaprayag',
                  'Dhaluwala',
                  'Dhandera',
                  'Dharchula',
                  'Didihat',
                  'Dineshpur',
                  'Dogadda',
                  'Doiwala',
                  'Dwarahat',
                  'Fatehpur Range, Dhamua Dunga Area',
                  'Gadarpur',
                  'Gangotri',
                  'Gochar(Gauchar)',
                  'Gumaniwala',
                  'Haldwani-cum-Kathgodam',
                  'Haldwani Talli',
                  'Hardwar(Haridwar)',
                  'Haripur Kalan',
                  'Herbertpur',
                  'Jagjeetpur',
                  'Jaspur',
                  'Jhabrera',
                  'Jiwangarh',
                  'Jonk',
                  'Joshimath(Jyotirmath)',
                  'Kaladhungi',
                  'Kanchal Gosain',
                  'Karnaprayag',
                  'Kashipur',
                  'Kashirampur',
                  'Kedarnath',
                  'Kela Khera',
                  'Khanjarpur',
                  'Kharak mafi',
                  'Khatima',
                  'Khatyari',
                  'Kichha',
                  'Kirtinagar',
                  'Kotdwara(Kotdwar)',
                  'Laksar',
                  'Lalkuan',
                  'Landaur(Landour)',
                  'Landhaura',
                  'Lansdowne',
                  'Lohaghat',
                  'Maholiya',
                  'Mahua Dabra Haripura',
                  'Mahua Kheraganj',
                  'Manglaur',
                  'Maohanpur Mohammadpur',
                  'Mehu Wala Mafi',
                  'Mukhani(Roopnagar, Basant Vihar Colony and Judges Farm)',
                  'Muni Ki Reti',
                  'Mussoorie',
                  'Nagala Imarti',
                  'Nagla',
                  'Nainital',
                  'Nainital Cantonment',
                  'Nandprayag(Nandaprayag)',
                  'Narendranagar',
                  'Natthan Pur',
                  'Natthuwa Wala',
                  'Padali Gujar',
                  'Padampur Sukhran',
                  'Pauri',
                  'Piran Kaliyar',
                  'Pithoragarh',
                  'Pratitnagar',
                  'Raipur',
                  'Ramnagar',
                  'Ranikhet',
                  'Rawali Mahdood',
                  'Rishikesh',
                  'Roorkee',
                  'Roorkee Cantonment',
                  'Rudraprayag',
                  'Rudrapur',
                  'Saidpura',
                  'Salempur Rajputan',
                  'Shafipur',
                  'Shahpur',
                  'Shaktigarh',
                  'Sitarganj',
                  'Srinagar',
                  'Sultanpur',
                  'Sunhaira',
                  'Tanakpur',
                  'Tehri',
                  'Umru Khurd',
                  'Uttarkashi',
                  'Vikasnagar',
                  'Virbhadra',
                ],
              },
              type: 'select',
              selectValues: 'message',
              input: true,
            },
          ],
          width: 6,
          offset: 0,
          push: 0,
          pull: 0,
          size: 'md',
          currentWidth: 6,
        },
      ],
      key: 'columns1',
      type: 'columns',
      input: false,
      tableView: false,
    },
    {
      type: 'button',
      label: 'Submit',
      key: 'submit',
      customClass: 'mt-8',
      saveOnEnter: true,
      block: true,
      disableOnInvalid: true,
      input: true,
      tableView: false,
    },
  ],
};
