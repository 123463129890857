import React, { useEffect, useState } from 'react';
import HorizontalStepper from './components/HorizontalStepper';
// import Validation from './Validation';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form, Templates } from '@formio/react';
import AthleteForm from './form/athlete';
import CoachForm from './form/coach';
// import SignupForm from './form/signup';
// import AthleteOnlySignupForm from './form/athleteOnlySignupForms/athleteOnlySignup';
import InstitutionForm from './form/institution';
// import tailwind from '@tsed/tailwind-formio';
import { sendLead, sendMobileOtp, sendOtp, signUpService } from './services/authentication';
import { useMutation } from 'react-query';
import './styles/index.css';
import uttarakhanadLogo from '../assets/img/logo2024.png';
import SFALogo from '../assets/img/SFALogo.svg';
import PhoneValidation from './PhoneVerification';
import { EditEmailForm, EditPhoneForm } from './form/editforms';
import { useRedirectIfLoggedIn } from 'hooks/useRedirectIfLoggedIn';
import SignUpFormik from './form/SignUpFormik';
// Formio.use(tailwind);
Templates.framework = 'tailwind';

let formio;
const setForm = (form) => {
  console.log({ form });
  formio = form;
};

const fullName = sessionStorage.formFields ? JSON.parse(sessionStorage.formFields).full_name : null;

const useInviteId = () => {
  const pattern = /\?invite_id=(\S*)/gm;
  return pattern.test(sessionStorage.redirectToURL);
};

const useInstitutionId = () => {
  const pattern = /\?institution=(\S*)/gm;
  const isInstitutionId = pattern.test(sessionStorage.redirectToURL);
  return isInstitutionId;
};

const useTournamentId = () => {
  const pattern = sessionStorage?.redirectToURL?.split('/');
  if (pattern && pattern.length > 1) return pattern[pattern.length - 1].split('?')[0];
};

export default function SignUp() {
  // if (sessionStorage.redirectToUrl) {
  //   const pattern = new URL(window.location.origin + sessionStorage.redirectToURL).searchParams;
  //   console.log(window.location.origin + sessionStorage.redirectToURL);
  //   const isInviteId = pattern.get('invite_id');
  //   console.log(isInviteId);
  // }
  const isInviteId = useInviteId();
  const isInstitutionId = useInstitutionId();

  const tournamentId = useTournamentId();
  useRedirectIfLoggedIn();

  const [formFields, setFormFields] = useState({
    role: 'athlete',
    country: 'India',
    state: 'Uttarakhand',
    ReceiveWhatsapp: true,
  });

  console.log('role', formFields);

  // eslint-disable-next-line no-unused-vars
  const [otpState, setOtpState] = useState('');
  const [mobileOtpState, setMobileOtpState] = useState('');
  const [resendAction, setResendAction] = useState(false);
  const [showResendMess, setShowResendMess] = useState(false);
  const history = useHistory(); 
  const step = useParams().step;
  const sendMobileOtpAction = ({ phone, ...data }) => {
    let name;
    if (data.role === 'athlete') name = data.athlete_full_name;
    if (data.role === 'institution') name = data.institution_full_name;
    if (data.role === 'coach') name = data.coach_full_name;

    return sendMobileOtpMutation.mutateAsync({ phone, name });
  };

  const sendMobileOtpMutation = useMutation(
    (data) => sendMobileOtp(data, sessionStorage.institution, tournamentId, sessionStorage.otp),
    {
      onSuccess: (res) => {
        if (res.message.status.includes('Request received to send template to')) {
          setMobileOtpState('success');
          if (resendAction) setShowResendMess(true);
        } else if (res.message === 'User Already Exists!') {
          setMobileOtpState('exists');
        } else {
          setMobileOtpState('error');
        }
      },
      onError: async (err) => {
        console.error('error -', err);
      },
    }
  );

  // eslint-disable-next-line no-unused-vars
  const sendOtpAction = (email) => sendOtpMutation.mutateAsync(email);

  const sendOtpMutation = useMutation(
    (data) => sendOtp(data, sessionStorage.institution, tournamentId, sessionStorage.otp),
    {
      onSuccess: (res) => {
        if (res.message === 'success') {
          setOtpState('success');
          if (resendAction) setShowResendMess(true);
        } else if (res.message === 'User Already Exists!') {
          setOtpState('exists');
        } else {
          setOtpState('error');
        }
      },
      onError: async (err) => {
        console.error('error -', err);
      },
    }
  );

  const handleSubmitAndVerify = async ({ data }, next) => {
    console.log({ data });
    await setMobileOtpState('');
    if (data.cancel && data.cancel === true) {
      setMobileOtpState('success');
      return;
    }
    try {
      setFormFields({ ...formFields, ...data });
      window.sessionStorage.setItem('formFields', JSON.stringify({ ...formFields, ...data }));
      await sendLead(data);
      await setMobileOtpState('');
      await setResendAction(false);
      await setShowResendMess(false);
      const r = await sendMobileOtp({
        name: data.athlete_full_name,
        phone: data.phone,
        institution: sessionStorage.institution,
        tournament: tournamentId,
        otp: sessionStorage.otp,
      });
      console.log(r);
      if (r) {
        setMobileOtpState('success');
        if (resendAction) setShowResendMess(true);
      }
      if (r?.message?.error) {
        next(r.message.error);
      }
    } catch (e) {
      console.error(e);
      next(e);
    }
  };

  // useEffect(() => {
  //   if (otpState === 'success') {
  //     // history.push('/signup/email-verify');
  //   }
  // }, [otpState]);

  useEffect(() => {
    if (mobileOtpState === 'success') {
      history.push('/signup/phone-verification');
    }
  }, [mobileOtpState]);

  // set state from session storage
  useEffect(() => {
    setTimeout(() => {
      const formFields = window.sessionStorage.getItem('formFields');
      if (formFields) {
        setFormFields(JSON.parse(formFields));
        console.log(formFields);
      }
    }, 500);
  }, [step]);

  useEffect(() => {
    console.log('step', step);
    window.onbeforeunload = () => {
      return true;
    };
  }, []);

  // const handleSubmitPersonalDetails = async ({ data }, next) => {
  //   if (data.cancel && data.cancel === true) {
  //     setMobileOtpState('success');
  //     return;
  //   }
  //   try {
  //     await setFormFields({ ...formFields, ...data });
  //     window.sessionStorage.setItem('formFields', JSON.stringify({ ...formFields, ...data }));
  //     await setMobileOtpState('');
  //     await setResendAction(false);
  //     await setShowResendMess(false);
  //     const r = await sendMobileOtp({
  //       name: data.athlete_full_name,
  //       phone: data.phone,
  //       institution: sessionStorage.institution,
  //       tournament: tournamentId,
  //       otp: sessionStorage.otp,
  //     });
  //     console.log(r);
  //     if (r) {
  //       setMobileOtpState('success');
  //       if (resendAction) setShowResendMess(true);
  //     }
  //     if (r?.message?.error) {
  //       next(r.message.error);
  //     }
  //   } catch (e) {
  //     console.error(e);
  //     next(e.message);
  //   }
  // };
 //  : step === 'email-verify' ? (
                //   <Validation
                //     email={formFields.email}
                //     formFields={formFields}
                //     setFormFields={setFormFields}
                //     otpState={otpState}
                //     setOtpState={setOtpState}
                //     sendOtpAction={sendOtpAction}
                //     resendAction={resendAction}
                //     setResendAction={setResendAction}
                //     setShowResendMess={setShowResendMess}
                //     showResendMess={showResendMess}
                //   />
                // ) 
  const handleSubmit = async (data, next) => {
    // console.log("hi")
    await setFormFields({ ...formFields, ...data });
    window.sessionStorage.setItem('formFields', JSON.stringify({ ...formFields, ...data }));
    // console.log(formFields)
    const datas= {...formFields, ...data};
    // console.log(datas)
    try {
      // console.log('handleSubmit Called');
      // console.log(data, formFields);
      // const institution = sessionStorage.institution;

      const r = await signupMutation.mutateAsync( datas );
      if (r.message.error) {
        console.error(r.message.error);
      }
      console.log('handleSubmit Called -', r);
    } catch (e) {
      console.error(e);
      next(e.message);
    }
  };

  const signupMutation = useMutation((data) => signUpService(data), {
    onSuccess: (res) => {
      console.log(res);
      if (res.message === 'success') {
        window.onbeforeunload = null;
        window.sessionStorage.removeItem('formFields');
        history.push('/athlete/profiles/profile');
      }
    },
    onError: async (err) => {
      console.error('error -', err);
    },
  });

  return (
    <>
      <div className="flex flex-row h-screen">
        <div
          id="blue-bg"
          className="hidden h-screen p-15 lg:flex lg:w-1/2 flex justify-center items-center flex-col text-left font-roboto font-bold"
        >
          <div className="">
          <img src={uttarakhanadLogo} className="h-26" alt={'sfa logo'} />
          </div>
          {/* <div className="mx-20 py-6 mr-8 mb-0 right-tagline font-bold font-poppins">
            Organize,
            <br />
            Play & <br />
            Track The
            <br />
            Easy Way
          </div> */}
          {/* <div className="middle-line bg-opacity-50 w-16 ml-20 mb-10 mt-0  h-1 bg-white" />
          <p className="px-20 py-0 right-info text-left text-white">
            Organize tournaments, manage teams, score <br />
            matches & keep track of your performance in <br />
            every match you play. It's Free!
          </p> */}
        </div>
        <div id="blue-bg" className="w-full lg:w-1/2 md:bg-white md:py-0">
          <img
            src={SFALogo}
            alt="Sfa Play Mobile Logo"
            className="block md:hidden h-12 p-2"
            onClick={() => {
              history.push('/');
            }}
          />
          <div className="py-8  px-5 bg-white sm:px-10  h-screen overflow-y-scroll  flex flex-col text-left">
            <div className="flex flex-col justify-start h-full w-full">
              <div>
                <h2 className="action text-xl md:text-3.5xl mb-1 md:mb-0">Create an Account</h2>
                <p className="pb-6 info">
                  Already a member? <Link to="/signin">Sign in</Link>
                </p>
              </div>
              <HorizontalStepper
                steps={[
                  {
                    title: 'Sign up',
                    description: 'Create an account',
                    active: !step,
                    done:
                      step === 'phone-verification'||
                      // step === 'email-verify' ||
                      step === 'personal-detailssss' ,
                      
                    href: '/signup/',
                  },
                
                  // {
                  //   title: 'Email Verification',
                  //   description: 'Verify your account',
                  //   active: step === 'email-verify' || step === 'edit-email',
                  //   done: step === 'personal-details' || step === 'phone-verification',
                  //   href: '/signup/email-verify',
                  // },
                  {
                    title: 'Phone Verification',
                    description: 'Verify your phone number',
                    active: step === 'phone-verification',
                    done: false,
                    href: '/signup/phone-verification',
                  },
                  {
                    title:
                      formFields.role === 'institution'
                        ? 'Institution Details'
                        : formFields.role === 'athlete'
                        ? 'Athlete Details'
                        : 'TO / Support Staff Details',
                    description: 'Add Personal Details',
                    active: step === 'personal-details' ,
                    done: step === 'phone-verification',
                    href: '/signup/personal-details',
                  },                 
                
                ]}
              />

              <div>
                {!step ? (
                  <>
                    {/*
                    {otpState === 'error' && (
                      <p className="text-red-500">Something went wrong. Please try again later.</p>
                    )}
                    {otpState === 'exists' && (
                      <p className="text-red-500">
                         User already exists, kindly signup with an alternate email-id.
                       </p>
                    )}
*/}

                    <SignUpFormik
                      handleSubmitAndVerify={handleSubmitAndVerify}
                      formFields={formFields}
                      setFormValues={setFormFields}
                      isAthleteOnly={isInviteId || isInstitutionId}
                      tournamentId={tournamentId}
                    />
                  </>
                )
                //  : step === 'email-verify' ? (
                //   <Validation
                //     email={formFields.email}
                //     formFields={formFields}
                //     setFormFields={setFormFields}
                //     otpState={otpState}
                //     setOtpState={setOtpState}
                //     sendOtpAction={sendOtpAction}
                //     resendAction={resendAction}
                //     setResendAction={setResendAction}
                //     setShowResendMess={setShowResendMess}
                //     showResendMess={showResendMess}
                //   />
                // ) 
                : step === 'personal-details' ? (
                  <>
                    {formFields.role === 'athlete' && (
                      <Form
                        form={AthleteForm}
                        submission={{
                          data: formFields,
                        }}
                        url={process.env.REACT_APP_API_URL}
                        options={{
                          hooks: {
                            beforeSubmit: handleSubmit,
                          },
                        }}
                        onSubmit={console.log}
                      />
                    )}
                    {formFields.role === 'coach' && (
                      <Form
                        form={CoachForm}
                        submission={{
                          data: formFields,
                        }}
                        url={process.env.REACT_APP_API_URL}
                        options={{
                          hooks: {
                            beforeSubmit: handleSubmit,
                          },
                        }}
                        onSubmit={console.log}
                      />
                    )}
                    {formFields.role === 'institution' && (
                      <Form
                        form={InstitutionForm}
                        submission={{
                          data: formFields,
                        }}
                        url={process.env.REACT_APP_API_URL}
                        options={{
                          hooks: {
                            beforeSubmit: handleSubmit,
                          },
                        }}
                        onSubmit={console.log}
                      />
                    )}
                  </>
                ) : step === 'phone-verification' ? (
                  <PhoneValidation
                    phone={formFields.phone}
                    formFields={formFields}
                    setFormFields={setFormFields}
                    otpState={mobileOtpState}
                    setOtpState={setMobileOtpState}
                    sendOtpAction={sendMobileOtpAction}
                    resendAction={resendAction}
                    setResendAction={setResendAction}
                    setShowResendMess={setShowResendMess}
                    showResendMess={showResendMess}
                    handleSubmit={handleSubmit}
                    // prefilledOtp={prefilledOtp}
                  />
                ) : (
                  (step === 'edit-email' || step === 'edit-phone') && (
                    <>
                      <h1 className={'font-bold text-sm'}>
                        {step === 'edit-email'
                          ? 'Edit Email Address for Verification'
                          : 'Edit Phone Number for Verification'}
                      </h1>
                      <EditForm
                        form={step === 'edit-email' ? EditEmailForm : EditPhoneForm}
                        formReady={setForm}
                        submission={{ data: { email: formFields.email, phone: formFields.phone } }}
                        url={process.env.REACT_APP_API_URL}
                        options={{
                          hooks: {
                            beforeSubmit: async ({ data }, next) => {
                              console.log('submit');
                              if (step === 'edit-email') {
                                await setOtpState('');
                                if (data.cancel && data.cancel === true) {
                                  setOtpState('success');
                                  return;
                                }
                                try {
                                  const res = await sendOtp(data.email);
                                  if (res.message === 'success') {
                                    setFormFields({ ...formFields, ...data });
                                    window.sessionStorage.setItem(
                                      'formFields',
                                      JSON.stringify({ ...formFields, ...data })
                                    );
                                    setOtpState('success');
                                  } else {
                                    next(res.message);
                                  }
                                  next();
                                } catch (e) {
                                  console.error(e);
                                  next(e);
                                }
                                // handleSubmitAndVerify(...data)
                              } else {
                                console.log({ data });
                                if (data.cancel && data.cancel === true) {
                                  setMobileOtpState('success');
                                  return;
                                }
                                console.log('data', data);
                                try {
                                  await setMobileOtpState('');
                                  await setResendAction(false);
                                  await setShowResendMess(false);
                                  const r = await sendMobileOtp({
                                    ...formFields,
                                    ...data,
                                    ...{ name: fullName },
                                  });
                                  // console.log(r);
                                  if (
                                    r.message.status.includes(
                                      'Request received to send template to'
                                    )
                                  ) {
                                    await setFormFields({ ...formFields, ...data });
                                    window.sessionStorage.setItem(
                                      'formFields',
                                      JSON.stringify({ ...formFields, ...data })
                                    );
                                    setMobileOtpState('success');
                                    if (resendAction) setShowResendMess(true);
                                  }
                                  if (r?.message?.error) {
                                    next(r.message.error);
                                  }
                                } catch (e) {
                                  console.error(e);
                                  next(e.message);
                                }
                              }
                            },
                          },
                        }}
                        onSubmit={console.log}
                      />
                      <div className={'flex mt-1'}>
                        <button
                          className={
                            'btn btn-primary btn-md bg-white text-gray-750 hover:bg-gray-250 font-semibold rounded mr-1'
                          }
                          onClick={history.goBack}
                        >
                          Cancel
                        </button>
                        <button
                          className={'btn btn-primary font-semibold btn-md btn-block ml-1'}
                          onClick={() => {
                            formio.submit();
                          }}
                        >
                          {step === 'edit-email' ? 'Change Email' : 'Change Phone'}
                        </button>
                      </div>
                    </>
                  )
                )}

                <p className="content hidden mt-5">
                  This site is protected by reCaptcha and the Google <a href="#">Privacy Policy</a>{' '}
                  and
                  <a href="/static/terms_and_conditions.pdf"> Terms & Conditions</a> apply
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const EditForm = React.forwardRef(({ data, ...props }, _ref) => {
  return (
    <Form
      submission={props.submission}
      formReady={props.formReady}
      form={props.form}
      onSubmit={console.log}
      {...props}
    />
  );
});
EditForm.displayName = 'EditForm';
